

<template>
  <template v-if="load">
    <NavBar />
    <router-view></router-view>
    <FooterVue />
  </template>
  <template v-else>
    <div id="preloader">
    <div class="jumper">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  </template>
</template>


<script>

import NavBar from './components/NavBar.vue';
import FooterVue from './components/Footer.vue';


import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  components: { NavBar, FooterVue },
  data() {
    return {
      load: false
    }
  },
  methods: {
    ...mapActions(['loadXMLFiles']),
  },
  mounted() {
    this.loadXMLFiles();
    setTimeout(() => {
      this.load = true;
    }, 1000);
  },
}


</script>

<style></style>
