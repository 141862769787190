// Composables
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../layouts/Home.vue'
import About from '../layouts/About.vue'
import Services from '../layouts/Services.vue'
import Apps from '../layouts/Apps.vue'
import Contact from '../layouts/Contact.vue'
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: { public: true }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: { public: true }
  },  
  {
    path: '/services-and-products',
    name: 'services',
    component: Services,
    meta: { public: true }
  },
  {
    path: '/applications',
    name: 'apps',
    component: Apps,
    meta: { public: true }
  },
  {
    path: '/contact-us',
    name: 'contact',
    component: Contact,
    meta: { public: true }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})


export default router
