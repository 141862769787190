
<template>
    <div class="page-heading header-text">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h1>Our Services</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="single-services mb-3">
        <div class="container">
            <div class="row ui-tabs ui-widget ui-widget-content ui-corner-all" id="tabs">
                <div class="col-lg-4">
                    <ul class="ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header active ui-corner-all"
                        role="tablist">
                        <li v-for="(item, index) in content.service" v-bind:key="index"
                            class="ui-state-default ui-corner-top"
                            :class="selected == index ? 'ui-tabs-active ui-state-active' : ''" role="tab" tabindex="-1"
                            :aria-selected="selected == index ? 'true' : 'false'"
                            :aria-expanded="selected == index ? 'true' : 'false'"><a @click="select(index)"
                                class="ui-tabs-anchor" role="presentation" tabindex="-1" id="ui-id-1">{{ item.title }} <i
                                    class="fa fa-angle-right"></i></a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-8">
                    <section id="service-content" class="tabs-content">
                        <article aria-labelledby="ui-id-1" v-for="(item, index) in content.service" v-bind:key="index"
                            class="ui-tabs-panel ui-widget-content ui-corner-bottom" role="tabpanel"
                            :aria-hidden="selected == index ? 'false' : 'true'"
                            :style="selected == index ? 'display: block;' : 'display: none;'">
                            <img style="border-radius: 8px;" height="400" :src="'website/images/' + item.image" alt="">
                            <h4>{{ item.title }}</h4>
                            <p v-html="item.text">
                            </p>
                        </article>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            selected: 0
        }
    },
    computed: {
        content() {
            return this.$store.state.content.services
        }
    },
    methods: {
        select(index) {
            this.selected = index;
            if (window.innerWidth < 992) {
                const divElement = document.getElementById('tabs');
                let totalHeight = 0;
                const anchorElements = divElement.getElementsByClassName('ui-tabs-anchor');
                for (let i = 0; i < anchorElements.length; i++) {
                    totalHeight += anchorElements[i].offsetHeight + 10;
                }
                window.scrollTo({
                    top: 250 + totalHeight,
                    behavior: 'smooth'
                });
            }

        }
    },
    mounted() {
    },
}
</script>
<style></style>