<template>

<div class="page-heading header-text">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h1>Our Applications</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="apps more-info about-info">
      <div class="container">
        <div class="row"> 
          <div v-for="(item, index) in content.app" v-bind:key="index" class="col-md-12 mb-5">
            <div class="more-info-content">
              <h2> {{index+1}}. {{ item.title }}</h2>
              <div class="row mt-3">
                <div class="col-md-7 align-self-center">
                  <div class="right-content">
                    
                    <p v-html="item.text">
                    </p>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="left-image">
                    <img :src="'/website/images/' + item.image" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  computed: {
    content(){
      return this.$store.state.content.apps
    }
  },
  methods: {
  },
  mounted() {
  },
}
</script>
<style></style>